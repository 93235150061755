import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Link } from "gatsby";
import Layout from "../components/layout";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const Pre = styled.pre`
  background-color: var(--purple);
  color: var(--white);
  border-radius: 10px;
  padding: 2rem;
  line-height: 1.25;
`;

const Code = styled.code`
  color: var(--white);
`;

const H1 = styled.h1`
  &::before {
    content: "";
    border-left: 5px solid var(--green);
    padding-right: 10px;
  }
`;

const Post = ({ data: { mdx } }) => {
  return (
    <Layout>
      <h1>{mdx.frontmatter.title}</h1>
      <hr />
      <MDXProvider
        components={{
          Link,
          h1: (props) => <H1 {...props} />,
          pre: (props) => <Pre {...props} />,
          code: (props) => <Code {...props} />,
          img: (props) => <img alt="" style={{ width: "100%" }} {...props} />,
        }}
      >
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MDXProvider>
      <Helmet>
        <title>{mdx.frontmatter.title}</title>
      </Helmet>
    </Layout>
  );
};

export default Post;

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
      }
    }
  }
`;
